$color-border-right-buttongroup: $white;
$color-border-color-buttongroup: $primary-base;
$icon-size-buttongroup-medium: $icon-medium;




#{$brandcall}{
    .MuiButtonGroup-contained{
        button.MuiButtonBase-root.MuiButton-root+button.MuiButtonBase-root.MuiButton-root {
            margin-left: 0px;
            // padding: $space-micro;
        }

        .MuiButtonGroup-firstButton{
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            // border-right: 2px solid $color-border-right-buttongroup;
            border-color: $color-border-color-buttongroup;
        }
        .MuiButtonGroup-root{
            // border-radius:none;
            box-shadow: none;
        }
        // .MuiButton-containedPrimary{
        //     padding:$space-condensed $space-default;
        // }
        .MuiSvgIcon-root{
            font-size: $icon-size-buttongroup-medium;
        }
     
    }
}
 
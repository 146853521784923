$brandcall : ".freya-fusion";

#{$brandcall} {

    .scroll-on-hover {
        overflow: hidden;

        &:hover {
            overflow-y: visible;

            &::-webkit-scrollbar-track:hover,
            &::-webkit-scrollbar:hover,
            &::-webkit-scrollbar-thumb:hover {
                width: 1rem !important;
            }
        }

        &::-webkit-scrollbar-track {
            background: rgba(0, 0, 0, .1);
            border-radius: 0.2rem;



        }

        &::-webkit-scrollbar {
            width: .5rem;
            border-radius: .2rem;

        }

        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb {
            overflow: visible;
            border-radius: .2rem;

        }

        &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, .3);
            border-radius: .2rem;

        }

    }
}
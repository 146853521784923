$font-size-muilist-large: $font-large;
$font-size-muilist-medium: $font-medium;




#{$brandcall} {
.MuiBox-root .MuiList-root {
    float: none;
    width: auto;
    display: contents;


    .MuiListItemIcon-root {
        min-width: 36px;

        svg {
            font-size: $font-size-muilist-large;

        }
    }

    .MuiListItemText-root {
        span {
            font-size: $font-size-muilist-medium;
        }
    }
}
}
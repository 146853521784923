$font-size-muicard-small: $font-small;
$font-size-muicard-base: $font-base;
$font-size-muicard-medium: $font-medium;
$font-size-muicard-large: $font-large;

$font-weight-muicard-bold: $text-bold;
$font-weight-muicard-bolder: $text-bolder;

$color-text-muicard: $black-900;
$color-text-muicard-link: $primary-600;
$color-text-muicard-secondary: $black-700;
$color-text-muicard-secondary-sub: $black-900;

$color-background-muicard-primary: $primary-100;

$line-height-muicard-small: $lineheight-small;

$space-padding-muicard-nospace: $space-nospace;
$space-padding-muicard-y: $space-condensed;
$space-padding-muicard-x: $space-condensed;
$space-padding-muicard-inner-x: $space-compact;
$space-padding-muicard-inner-y: $space-default;

$space-padding-muicard-micro: $space-micro;

$border-radius-muicard-secondary: $border-radius-secondary;

#{$brandcall} {
  .MuiCard-root {
    width: 275px;
    padding: $space-padding-muicard-inner-x $space-padding-muicard-inner-y;
    margin: 4px;
    display: inline-block;

    .card-action-link {
      border-radius: $border-radius-muicard-secondary;
      padding: $space-padding-muicard-nospace $space-padding-muicard-x;
      font-weight: $font-weight-muicard-bold;
      font-size: $font-size-muicard-small;
      color: $color-text-muicard-link;
      background-color: $color-background-muicard-primary;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    .MuiCardContent {
      padding-top: $space-padding-muicard-nospace;
    }

    .MuiTypography-h5 {
      font-size: $font-size-muicard-large;
      font-weight: $font-weight-muicard-bolder;
      color: $color-text-muicard;
      // width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .MuiTypography-body1 {
      font-size: $font-size-muicard-base;
      line-height: $line-height-muicard-small;
      color: $color-text-muicard-secondary;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-bottom: 0px;
    }

    .MuiTypography-body2 {
      font-size: $font-size-muicard-base;
      line-height: $line-height-muicard-small;
      color: $color-text-muicard-secondary;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }

    .MuiTypography-body3 {
      font-size: $font-size-muicard-base;
      // color: $color-text-muicard-secondary;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: $color-text-muicard-secondary-sub;
    }

    .MuiTypography-h6 {
      font-size: $font-size-muicard-medium;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: $black-900;
      font-weight: $font-weight-muicard-bolder;
    }
  }

  .MuiCardContent-root:last-child {
    padding: $space-padding-muicard-y $space-padding-muicard-nospace;
  }

  .MuiCardActions-spacing {
    justify-content: space-between;
    padding-left: $space-padding-muicard-nospace;
  }
}

// card three//

#{$brandcall} {
  .MuiCard-root {
    width: 275px;
    padding: $space-padding-muicard-inner-x $space-padding-muicard-inner-y;
    margin: 4px;
    display: inline-block;
    .card-count {
      padding: $space-padding-muicard-nospace $space-padding-muicard-x;
      font-weight: $font-weight-muicard-bolder;
      font-size: $font-size-muicard-base;
      color: $color-text-muicard-secondary-sub;
    }

    .card-link {
      border-radius: $border-radius-muicard-secondary;
      padding: $space-padding-muicard-nospace $space-padding-muicard-x;
      font-weight: $font-weight-muicard-bold;
      font-size: $font-size-muicard-small;
      color: $color-text-muicard-link;
      background-color: $color-background-muicard-primary;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      width: 60px;
    }

    .MuiCardContent-root img {
      width: 120px;
    }

    .MuiCardContent {
      padding-top: $space-padding-muicard-nospace;
    }

    .MuiTypography-body4 {
      font-size: $font-size-muicard-base;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: $color-text-muicard-secondary-sub;
      padding-bottom: $space-padding-muicard-micro;
    }

    .MuiTypography-body5 {
      font-size: $font-size-muicard-base;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: $color-text-muicard-secondary-sub;
    }

    .MuiTypography-h4 {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: $font-size-muicard-base;
      font-weight: $font-weight-muicard-bolder;
      color: $color-text-muicard;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .MuiCardContent-root:last-child {
    padding: $space-padding-muicard-y;
  }

  .MuiCardActions-spacing {
    justify-content: space-between;
    padding-left: $space-padding-muicard-nospace;
  }
}

//Temporay fix
#{$brandcall} {
  .main-app-innerpage
    > .MuiCard-root
    .MuiCardContent-root.fullwidth-card
    .MuiTablePagination-root
    .MuiToolbar-root
    * {
    font-size: 12px !important;
  }
}
#{$brandcall} {
  .react-table {
    .MuiTablePagination-root .MuiToolbar-root * {
      font-size: 12px !important;
    }
  }

  .MuiDayPicker-weekContainer {
    .MuiButtonBase-root.Mui-selected {
      background-color: $color-background-muicard-primary;
    }
    * {
      font-size: 14px !important;
    }
  }
}

@import '../abstract/variables';
@import '../abstract/component-variables';

$border-muibutton-none: $no-border;
$border-muibutton-thin: $border-thin;
$border-radius-muibutton-base: $border-radius-base;

$space-padding-muibutton-y: $space-condensed;
$space-padding-muibutton-x: $space-default;

$color-text-muibutton: $white;
$color-text-muibutton-secondary: $black-900;
$color-text-muibutton-primary-link: $primary-600;

$color-background-muibutton-success-hover: $success-100;
$color-background-muibutton-danger-hover: $danger-100;

$color-background-muibutton-seondary: $black-100;
$color-background-muibutton-seondary-hover: $black-200;

$font-size-muibutton-base: $font-base;
$font-weight-muibutton-bold: $text-bold;
$font-weight-muibutton-bolder: $text-bolder;

$line-height-muibutton-small: $lineheight-small;

$icon-padding-muibutton-y: $space-nospace;
$icon-padding-muibutton-x: $space-micro;




$mui-buttons: (
    "containedPrimary":$primary-600,
    "containedSuccess":$success-700,
    "containedDanger":$danger-800,
    "containedWarning":$warning-400,
    "containedSecondary":$black-100,

    ) !default;


// Button Mixings
@mixin MuiButton-contained($bg-color) {
    text-decoration: none;
    cursor: pointer;
    border: $border-muibutton-none;
    padding: $space-padding-muibutton-y $space-padding-muibutton-x;
    border-radius: $border-radius-muibutton-base;
    background-color: $bg-color;
    color: $color-text-muibutton;
    font-size: $font-size-muibutton-base;
    font-weight: $font-weight-muibutton-bold;
    line-height: $line-height-muibutton-small;
    text-transform: none;
    box-shadow: none;
    border: $border-muibutton-none;
    //border: 1px solid transparent;
    letter-spacing: 0px;

    .MuiButton-startIcon {
        margin-right: $space-micro;
         display: inline;
         line-height: 12px;
        .MuiSvgIcon-root{
            font-size: $font-medium;
        }
    }
    .MuiButton-endIcon{
        margin-left: $space-micro;
         display: inline;
         line-height: 12px;
        .MuiSvgIcon-root{
            font-size: $font-medium;
        }
    }

    em {
        padding: $icon-padding-muibutton-y $icon-padding-muibutton-x;
    }

    +.btn {
        margin-left: 14px;
    }

}

$brandcall : ".freya-fusion";

@each $key, $val in $mui-buttons {
    #{$brandcall} .MuiButton-#{$key} {
        @include MuiButton-contained($val
    );

    &:hover {
        background-color: darken($val, 10%);
        box-shadow: none;
    }

    &[disabled] {
        pointer-events: none;
        opacity: 0.35;
    }

    &:active {
        background-color: darken($val, 20%);
    }

    &:focus {
        background-color: darken($val, 20%);
    }
}

@if $key =='containedSecondary' {
    #{$brandcall} .MuiButton-#{$key} {
        color: $black-900;
    }
}

}

$mui-outlinedbuttons: (
    "outlinedPrimary":$primary-600,
    "outlinedSuccess":$success-700,
    "outlinedError":$danger-800,

    ) !default;

@mixin mui-outlinedbuttons($border-color) {
    text-decoration: none;
    cursor: pointer;
    border: 1px solid $border-color;
    padding: $space-padding-muibutton-y $space-padding-muibutton-x;
    border-radius: $border-radius-muibutton-base;
    background-color: transparent;
    color: $border-color;
    font-size: $font-size-muibutton-base;
    font-weight: $font-weight-muibutton-bolder;
    line-height: $line-height-muibutton-small;
    text-transform: none;

    .MuiButton-startIcon {
        margin-right: $space-micro;
         display: inline;
         line-height: 12px;
        .MuiSvgIcon-root{
            font-size: $font-medium;
        }
    }
    .MuiButton-endIcon{
        margin-left: $space-micro;
         display: inline;
         line-height: 12px;
        .MuiSvgIcon-root{
            font-size: $font-medium;
        }
    }


    em {
        margin: 0px 4px 0px -4px;
    }

    +.btn {
        margin-left: 14px;
    }

}

@each $key, $val in $mui-outlinedbuttons {
    #{$brandcall} .MuiButton-#{$key} {
        @include mui-outlinedbuttons($val
    );

    &:hover {
        // background-color: lighten($val, 50%);
        background-color: $white;
        // border: 2px solid transparent;
    }

    &[disabled] {
        pointer-events: none;
        opacity: 0.35;
    }

    &:active {
       // background-color: lighten($val, 50%);
       background-color: $primary-100;
    }

    &:focus {
        // border: 1px solid lighten($val, 40%) ;
        border: 1px solid $color-text-muibutton-primary-link;
        background-color: $primary-100;
    }
}

@if $key =='outlinedSuccess' {

    #{$brandcall} .MuiButton-#{$key} {
        color: $color-text-muibutton-secondary;

        &:hover {
            background-color: $color-background-muibutton-success-hover;
        }

    }

}

@if $key =='outlinedError' {

    #{$brandcall} .MuiButton-#{$key} {
        color: $color-text-muibutton-secondary;

        &:hover {
            background-color: $color-background-muibutton-danger-hover;
        }
    }

}
}


#{$brandcall} .MuiButton-textPrimary {
    text-decoration: none;
    cursor: pointer;
    padding: $space-padding-muibutton-y $space-padding-muibutton-x;
    border-radius: $border-radius-muibutton-base;
    background-color: transparent;
    color: $color-text-muibutton-primary-link;
    font-size: $font-size-muibutton-base;
    font-weight: $font-weight-muibutton-bolder;
    line-height: $line-height-muibutton-small;
    text-transform: none;

    .MuiButton-startIcon {
        margin-right: $space-micro;
         display: inline;
         line-height: 12px;
        .MuiSvgIcon-root{
            font-size: $font-medium;
        }
    }
    .MuiButton-endIcon{
        margin-left: $space-micro;
         display: inline;
         line-height: 12px;
        .MuiSvgIcon-root{
            font-size: $font-medium;
        }
    }


    &:hover {

        background-color: lighten($primary-600, 50%);
        text-decoration: none;
    }

}

#{$brandcall} .MuiButton-textPrimary[disabled] {
    pointer-events: none;
    opacity: 0.35;
}

#{$brandcall} .MuiButton-textPrimary {
    &.btn-secondary {
        background: $color-background-muibutton-seondary;
        color: $color-text-muibutton-secondary;

        &:hover {
            background: $color-background-muibutton-seondary-hover;
        }

    }
}

#{$brandcall} button.MuiButtonBase-root.MuiButton-root+button.MuiButtonBase-root.MuiButton-root {
    margin-left: 8px;
}

// #{$brandcall} .MuiButtonGroup-root {
//     box-shadow: none;

//     button.MuiButtonBase-root.MuiButton-root {
//         padding: 5px 12px;
//     }

//     button.MuiButtonBase-root.MuiButton-root+button.MuiButtonBase-root.MuiButton-root {
//         margin-left: 0px;
//     }
// }

// #{$brandcall} button.MuiButtonBase-root{
//     margin-left: 8px;

// }
$color-background-toaster-success: $success-700;
$color-background-toaster-warning: $warning-700;
$color-background-toaster-danger: $danger-700;
$color-text-toaster: $white;
$text-size-toaster-message: $font-base;

#{$brandcall} {
    .MuiSnackbar-root{
        border: 0px;
        .MuiAlert-filledWarning{
            background-color: $color-background-toaster-warning;
        }
        .MuiAlert-filledError{
            background-color: $color-background-toaster-danger;
             
        }
        .MuiAlert-filledSuccess{
             background-color: $color-background-toaster-success;
            
        }
       
    }
}

$radius-border-muigrid: $border-radius-secondary;

$border-none-muigrid-header: $space-nospace;
$weight-font-muigrid-header: $text-regular;
$weight-font-muigrid-normal: $text-regular;
$weight-font-muigrid-bold: $text-bold;
$weigt-buttin-muigrid-link: $text-bold;
$weight-font-muibutton: $text-bold;

$space-muibutton: $space-condensed $space-default;
$space-top-muigrid: $space-condensed;
$space-item-muigrid: $space-nospace $space-condensed;
$space-muigrid-header: $space-micro 10px;
$space-muigrid-row: $space-micro 0px;
$space-muigrid: $space-micro 10px;
$space-no-muigrid: $space-nospace;
$space-toolbar-muigrid: $space-condensed;
$space-input-muigrid: $space-nano 10px;

$size-font-muigrid-header: $font-base;
$size-icon-muigrid-header: $font-medium;
$size-font-muigrid-body: $font-base;
$size-font-muigrid-muichip: $font-small;
$size-font-muigrid-small: $font-small;
$size-font-pagination: $font-base;
$size-font-actionicon: $font-medium;

$size-font-listitem: $font-base;
$size-font-listicon: $font-medium;

$color-background-muigrid-footer: $black-50;
$color-muigrid-primary: $primary-600;
$color-muigrid-link: $primary-600;
$color-muigrid-link-hover: $primary-900;
$color-text-muigrid-black: $black-900;
$color-text-muigrid-black-1000: $black-1000;
$color-background-muigrid-button-hover: $black-100;
$color-text-muigrid: $black-1000;
$color-svgIcon-button: $primary-600;

#{$brandcall} .MuiDataGrid-menu .MuiDataGrid-menuList {
  padding: 8px 0 !important;
  display: inline-block;

  li {
    padding: 8px 20px;
    font-size: $size-font-listitem;

    .MuiListItemIcon-root {
      min-width: 28px;

      svg.MuiSvgIcon-root {
        font-size: $size-font-listicon;
      }
    }
  }
}

#{$brandcall} .react-table {
  // padding: $space-muigrid;
  background: $white;
  margin-top: $space-top-muigrid;
  // border-radius: $radius-border-muigrid !important;
  // box-shadow: 0 0 6px rgba(0, 0, 0, 0.12), 0 0 3px rgb(185 179 179 / 14%);
  border: none;
  height: auto;
  max-height: 79vh;

  &.table_size-has--row1 {
    .MuiDataGrid-columnHeaders
      + div[role="presentation"].MuiDataGrid-virtualScroller {
      height: 64vh;
    }
  }
  &.table_size-has--row2 {
    .MuiDataGrid-columnHeaders
      + div[role="presentation"].MuiDataGrid-virtualScroller {
      height: 56vh;
    }
  }

  .MuiDataGrid-columnHeaders {
    background: #f9fafb;
    border: 1px solid #e0e0e0;
    border-bottom: 0;
    //  border-top-width: $border-none-muigrid-header !important;

    .MuiDataGrid-columnHeadersInner,
    .MuiDataGrid-pinnedColumnHeaders {
      background: #f9fafb;
      border-color: rgba(224, 224, 224, 1);
    }

    + div[role="presentation"] {
      border: 1px solid #e0e0e0;

      > .MuiDataGrid-virtualScrollerContent {
        // height: auto !important;
        //min-height: 100px !important;
      }
      &:not(:has(.MuiDataGrid-pinnedColumns)) {
        > div[role="presentation"]:only-child {
          height: unset !important;
          .MuiDataGrid-virtualScrollerRenderZone {
            position: relative !important;
          }
        }
      }
    }

    .MuiDataGrid-columnHeaderTitle {
      // color: $black-1000;
      color: $color-text-muigrid-black;
      font-size: $size-font-muigrid-header;
      font-weight: $text-bolder;
    }

    &:last-child {
      max-width: 300px;
    }

    .MuiDataGrid-columnHeader {
      font-size: $size-font-muigrid-header !important;
      font-weight: $weight-font-muigrid-header !important;
      height: auto !important;
      // padding: $space-muigrid-header !important;
      color: #566075;
      background: #f9fafb;

      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }

      &.MuiDataGrid-columnHeaderCheckbox {
        min-width: 20px !important;
        max-width: 36px !important;

        .MuiCheckbox-root {
          opacity: 0;

          &.Mui-checked {
            opacity: 1;
          }
        }

        svg path {
          color: #999;
        }

        .Mui-checked {
          svg path {
            color: $color-muigrid-primary;
          }
        }
      }

      &:hover {
        &.MuiDataGrid-columnHeaderCheckbox {
          .MuiCheckbox-root {
            opacity: 1;
          }
        }
      }
    }
  }

  &.MuiDataGrid-root--densityStandard {
    .MuiDataGrid-columnHeaders {
      max-height: 38px !important;
      min-height: 38px !important;
      line-height: 30px !important;
    }

    .MuiDataGrid-row {
      max-height: 48px !important;
      min-height: 48px !important;

      //padding: $space-muigrid-row;
      .MuiDataGrid-cell {
        max-height: 48px !important;
        min-height: 48px !important;
      }
    }

    .MuiDataGrid-columnHeaderTitle {
      line-height: 38px !important;
    }
  }

  &.MuiDataGrid-root--densityComfortable {
    .MuiDataGrid-columnHeaders {
      max-height: 48px !important;
      min-height: 48px !important;
      line-height: 40px !important;
    }

    .MuiDataGrid-row {
      max-height: 58px !important;
      min-height: 58px !important;

      //padding: $space-muigrid-row;
      .MuiDataGrid-cell {
        max-height: 58px !important;
        min-height: 58px !important;
      }
    }

    .MuiDataGrid-columnHeaderTitle {
      line-height: 48px !important;
    }
  }

  .MuiDataGrid-row,
  .MuiDataGrid-row * {
    font-size: $size-font-muigrid-body;
    // color: $black-800;
    color: #242424;
  }

  .MuiDataGrid-row {
    border-bottom: 1px solid #f2f2f2;

    .MuiLink-button {
      font-weight: $weigt-buttin-muigrid-link !important;
    }

    .MuiChip-label {
      font-size: $size-font-muigrid-muichip !important;
    }

    svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
      font-size: $size-icon-muigrid-header !important;
    }

    .MuiDataGrid-cell {
      border: none;
      //  min-height: 30px !important;
      //  border-bottom: 1px solid #f2f2f2;

      .MuiDataGrid-cellContent {
        color: #646775;
      }

      a,
      .MuiLink-root {
        color: $color-muigrid-link;
        text-decoration: none;

        &:hover {
          color: $color-muigrid-link-hover;
        }
      }

      &[data-colindex="2"] {
        .MuiDataGrid-cellContent,
        * {
          color: $color-text-muigrid-black;
          font-weight: 500;
        }

        a,
        .MuiLink-root {
          color: $color-muigrid-link;
          text-decoration: none;

          &:hover {
            color: $color-muigrid-link-hover;
          }
        }
      }
    }

    .MuiDataGrid-actionsCell {
      .MuiIconButton-root svg {
        font-size: $size-font-actionicon;
      }
    }

    .MuiDataGrid-cellCheckbox {
      opacity: 0;
      min-width: 20px !important;
      max-width: 36px !important;
      // border-bottom: 1px solid #f2f2f2;

      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
      }

      svg path {
        color: #999;
      }

      .Mui-checked {
        svg path {
          color: $color-muigrid-primary;
        }
      }
    }

    &:hover,
    &.Mui-selected {
      .MuiDataGrid-cellCheckbox {
        opacity: 1;
      }
    }
  }

  .MuiDataGrid-footerContainer {
    min-height: 40px;
    padding-top: 0 !important;
    background: $color-background-muigrid-footer;
    border: 1px solid #e0e0e0;
    border-top: 0;
    border-radius: 0 0 6px 6px;

    .MuiTablePagination-toolbar {
      min-height: 32px;
    }

    .MuiInputBase-root.MuiTablePagination-input {
      padding: 0.2rem 0.75rem;
    }

    .MuiTablePagination-actions {
      .MuiButtonBase-root {
        padding: 0;
        margin: 0 8px;
      }
    }
  }

  //Ashwin
  .MuiDataGrid-cell {
    border-bottom: 0px solid !important;

    &:focus-within {
      outline-width: 0 !important;
    }

    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  // Fixing height for table content
  &.table_size-has--row1 {
    .MuiDataGrid-columnHeaders
      + div[role="presentation"].MuiDataGrid-virtualScroller {
      height: 64vh;
    }
  }
  &.table_size-has--row2 {
    .MuiDataGrid-columnHeaders
      + div[role="presentation"].MuiDataGrid-virtualScroller {
      height: 56vh;
    }
  }
}

#{$brandcall} .MuiGrid-root.MuiGrid-container > .MuiGrid-item {
  padding: $space-item-muigrid;
}

// #{$brandcall} .MuiDialog-root {
//     .MuiGrid-root.MuiGrid-container>.MuiGrid-item {
//         padding: $space-nospace;
//     }
// }

.grid_height-fixed {
  overflow-y: auto;
  max-height: calc(100vh - 130px);
  // max-height: 500px;
}

.grid_pagination {
  p,
  div {
    font-size: $size-font-pagination;
  }
}

.MuiDataGrid-toolbarContainer {
  // background: #f4f3f5;
  padding: $space-no-muigrid !important;
  margin-bottom: $space-toolbar-muigrid;
  justify-content: end;
  flex-direction: row-reverse;

  .MuiFormControl-root {
    padding: $space-no-muigrid !important;
  }

  button.MuiButtonBase-root.MuiButton-root {
    color: $color-text-muigrid-black-1000;
    background: transparent !important;
    display: inline-flex;
    font-weight: 500;
    font-size: $size-font-muigrid-small;

    &:hover,
    &:active,
    &:focus {
      background: $color-background-muigrid-button-hover !important;
    }
  }

  .MuiInputBase-root.MuiInput-root {
    padding: $space-input-muigrid;
    border-bottom: 1px solid #e1e1e1;

    > svg {
      font-size: 16px;
      margin-right: 4px;

      path {
        d: path("M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z");
      }
    }

    > .MuiButtonBase-root {
      svg {
        font-size: 16px;
        > path {
          d: path(
            "M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          );
        }
      }
    }
  }

  .MuiFormControl-root .MuiInput-underline {
    &::before,
    &::after {
      opacity: 0;
    }
  }

  .MuiButtonBase-root .MuiButton-startIcon .MuiSvgIcon-root {
    fill: $color-svgIcon-button;
    font-size: $font-large;
  }
}

// button.MuiButtonBase-root.MuiButton-root {
//     font-weight: $weight-font-muibutton !important;
//     padding: $space-muibutton !important;
// }

.MuiTablePagination-root {
  .MuiTablePagination-toolbar {
    p {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

// Filter Issues Fixed
$color_1: #fff;
$color_2: #111;
$color-background-filterbutton-primary: $color-text-muigrid-black;
$color-background-filterbutton-primary-hover: $color-text-muigrid-black-1000;
$color-background-filterbutton-danger: $color-text-muigrid-black;
$color-background-filterbutton-danger-hover: $color-text-muigrid-black-1000;

#{$brandcall} {
  .MuiDataGrid-panel:has(.MuiDataGrid-filterForm) {
    min-width: 620px;

    .MuiDataGrid-panelFooter {
      float: left;
      border-top: 1px solid #eee;
      margin-top: 16px;
      padding: 8px;
      flex-direction: row-reverse;

      button.MuiButtonBase-root.MuiButton-text.MuiButton-textPrimary {
        font-size: $size-font-muigrid-body;
        font-weight: $weight-font-muigrid-normal;
        color: $color-background-filterbutton-primary;
        padding: 4px 8px;
        white-space: nowrap;
        //  color: $color_1;

        &:hover {
          color: $color-background-filterbutton-primary-hover;
          background: $color-background-muigrid-button-hover;
        }

        > span {
          svg {
            font-size: 16px;
          }

          margin-right: 4px;
          margin-top: 0;
        }

        + {
          button.MuiButtonBase-root.MuiButton-text.MuiButton-textPrimary {
            // color: $color_1;
            color: $color-background-filterbutton-danger;

            &:hover {
              color: $color-background-filterbutton-danger-hover;
              background: $color-background-muigrid-button-hover;
            }
          }
        }
      }
    }

    .MuiDataGrid-filterFormValueInput {
      > .MuiBox-root {
        margin-top: 16px;
      }

      .MuiInputBase-root.MuiInputBase-formControl {
        min-height: 36px;
      }
    }

    .MuiDataGrid-filterForm {
      padding-bottom: 0;
      align-self: flex-end;

      .MuiFormControl-root {
        .MuiInputBase-root.MuiInput-root {
          margin: 0;
          border: 2px solid #eee;
          padding: 2px 4px;
          border-width: 2px 1px 2px 2px !important;

          &:before {
            display: none;
          }

          &:after {
            display: none;
          }
        }

        .MuiFormLabel-root.MuiInputLabel-animated {
          display: none;
        }

        + {
          .MuiFormControl-root {
            margin-right: 8px;
          }
        }
      }

      &:first-child {
        .MuiFormControl-root {
          .MuiFormLabel-root.MuiInputLabel-animated {
            display: block;
            font-size: $size-font-muigrid-body;
            margin: 4px 0 4px 0;
            font-weight: $weight-font-muigrid-bold;
            color: $color_2 !important;
          }
        }
      }

      + {
        .MuiDataGrid-filterForm {
          margin-left: 0px;
        }
      }
    }
  }
}

#{$brandcall} {
  .MuiPopper-root.MuiDataGrid-menu
    .MuiPaper-root
    > ul
    > li
    .MuiListItemText-root
    * {
    font-size: 14px;
  }

  .MuiPopper-root.MuiDataGrid-menu
    .MuiPaper-root
    > ul
    > li
    .MuiListItemIcon-root
    * {
    font-size: 16px;
  }
}

// .freya-fusion
//   .main-app-innerpage
//   .react-table
//   .MuiDataGrid-columnHeaders
//   + div[role="presentation"]
//   > .MuiDataGrid-virtualScrollerContent {
//   // Temp fix
//   height: auto !important;
//   > .MuiDataGrid-virtualScrollerRenderZone {
//     position: relative;
//   }
//   // Temp fix end
// }

@import '../abstract/variables';
@import '../abstract/component-variables';


$color-text-muitooltip: $white;
$color-background-muitooltip: $black-900;
$font-size-muitooltip-small: $font-small;
$font-size-muitooltip-base: $font-base;
$font-weight-muitooltip-regular: $text-regular;

$line-height-muitooltip-small: $lineheight-small;

$space-padding-muitooltip-y: $space-condensed;
$space-padding-muitooltip-x: $space-default;


#{$brandcall} {

    // .MuiButton-textSizeMedium{
    //     color:$black-900 !important;
    //     font-weight: 400 !important;
    //     &:hover {
    //      background-color: $black-200 !important;
    //     }
    // }
    .MuiTooltip-tooltip {
        color: $color-text-muitooltip;
        background-color: $color-background-muitooltip;
        font-size: $font-size-muitooltip-small;
        font-weight: $font-weight-muitooltip-regular;
        // min-width: 10px;
        max-width: 500px;
        padding: $space-padding-muitooltip-y $space-padding-muitooltip-x;
        line-height: $line-height-muitooltip-small;
    }

}
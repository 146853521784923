
// Do not edit directly
// Generated on Tue, 05 Mar 2024 12:15:54 GMT

$foundations-primary-100: #e7ecfc;
$foundations-primary-200: #b8c6f7;
$foundations-primary-300: #a0b3f5;
$foundations-primary-400: #708cf0;
$foundations-primary-500: #4166eb;
$foundations-primary-600: #295ef1;
$foundations-primary-700: #1b379b;
$foundations-primary-800: #172e81;
$foundations-primary-900: #122567;
$foundations-primary-base: #0c66e4;
$foundations-primary-primary: #2953e8;
$foundations-success-100: #e3f7f0;
$foundations-success-200: #c6efe1;
$foundations-success-300: #aae7d1;
$foundations-success-400: #71d8b3;
$foundations-success-500: #39c894;
$foundations-success-600: #00b876;
$foundations-success-700: #007b4f;
$foundations-success-800: #006642;
$foundations-success-900: #00291a;
$foundations-success-base: #00b876;
$foundations-black-50: #f9fafb;
$foundations-black-100: #f2f2f2;
$foundations-black-200: #d9d9d9;
$foundations-black-300: #bfbfbf;
$foundations-black-400: #a6a6a6;
$foundations-black-500: #8c8c8c;
$foundations-black-600: #737373;
$foundations-black-700: #595959;
$foundations-black-800: #404040;
$foundations-black-900: #262626;
$foundations-black-1000: #0d0d0d;
$foundations-danger-100: #ffe3e3;
$foundations-danger-200: #ffc6c6;
$foundations-danger-300: #ffaaaa;
$foundations-danger-400: #ff8e8e;
$foundations-danger-500: #ff3939;
$foundations-danger-600: #ff0000;
$foundations-danger-700: #e30000;
$foundations-danger-800: #aa0000;
$foundations-danger-900: #710000;
$foundations-danger-base: #ff0000;
$foundations-orange-100: #fffae6;
$foundations-orange-200: #fff0b3;
$foundations-orange-300: #ffe380;
$foundations-orange-400: #ffc400;
$foundations-orange-500: #ffab00;
$foundations-orange-600: #ff991f;
$foundations-orange-700: #ff8b00;
$foundations-orange-800: #c64a10;
$foundations-orange-900: #8e350c;
$foundations-orange-base: #ff5f15;
$foundations-info-100: #f8feff;
$foundations-info-200: #f0fdff;
$foundations-info-300: #e9fcff;
$foundations-info-400: #d3f9ff;
$foundations-info-500: #c4f8ff;
$foundations-info-600: #98f2ff;
$foundations-info-700: #95e2ed;
$foundations-info-800: #8ec1c9;
$foundations-info-900: #8ab1b6;
$foundations-info-base: #98f2ff;
$foundations-neutral-black: #000000;
$foundations-neutral-white: #ffffff;
$foundations-blueneutrals-100: #FFF;
$foundations-blueneutrals-200: #F7F8F9;
$foundations-blueneutrals-300: #F1F2F4;
$foundations-blueneutrals-400: #DCDFE4;
$foundations-blueneutrals-500: #B3B9C4;
$foundations-blueneutrals-600: #626F86;
$foundations-blueneutrals-700: #44546F;
$foundations-blueneutrals-800: #2C3E5D;
$foundations-blueneutrals-900: #172B4D;
$foundations-blueneutrals-1000: #091E42;
$foundations-purple-100: #eae6ff;
$foundations-purple-200: #c0b6f2;
$foundations-purple-300: #eae6ff;
$foundations-purple-400: #c0b6f2;
$foundations-purple-500: #998dd9;
$foundations-purple-600: #8777d9;
$foundations-purple-700: #6554c0;
$foundations-purple-800: #5243aa;
$foundations-purple-900: #403294;

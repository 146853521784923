$color-border-transferlist: $black-200;
$space-transferlist: 6px;
$space-header-transferlist: $foundations-space-condensed;
$space-micro-transferlist: $foundations-space-nano;

#{$brandcall} {
    .transferlist-main {
        .MuiGrid-item {
            .MuiPaper-root {
                border: 1px solid $color-border-transferlist;
                box-shadow: none !important;

                .MuiList-root {
                    min-height: auto;
                    min-width: 290px;
                    overflow-y: auto;
                }

                .MuiButtonBase-root {
                    padding: $space-transferlist;

                }

                .MuiCardHeader-root {
                    padding-left: $space-header-transferlist;
                    padding-right: $space-header-transferlist;
                }

                MuiCardHeader-root {
                    padding-left: $space-transferlist;
                }

                .MuiCardHeader-avatar {
                    padding-right: $space-micro-transferlist;
                    margin-right: $space-transferlist;
                }

                .MuiListItemIcon-root {
                    min-width: 40px;
                }

                .MuiCardHeader-content {
                    span {
                        font-size: $font-base;
                        font-weight: $text-bolder;
                        color: $black-800;
                    }

                    .MuiCardHeader-subheader {
                        font-size: $font-base;
                        font-weight: $text-bold;
                        color: $black-800;
                    }
                }


                .MuiListItemText-root {
                    color: $black-800;
                }


            }

            .MuiGrid-root {
                .MuiButton-outlinedPrimary {
                    border-width: 2px;

                }

            }
        }

        button.MuiButtonBase-root.MuiButton-root+button.MuiButtonBase-root.MuiButton-root {
            margin-left: 0px;
        }
    }
}
@import '../abstract/variables';
@import '../abstract/component-variables';
$color-text-muitab-active: $primary-600;
$color-text-muitabs: $black-900;
$color-border-muitabs: $black-200;
$color-border-muitabs-active: $primary-600;
$color-list-muitabs-hover: $black-200;
$color-background-muitabs: $black-100 ;

$font-size-muitabs-base: $font-base;

$font-weight-muitabs-bolder: $text-bolder;

$space-padding-muitabs-y: $space-nano;
$space-padding-muitabs-x: $space-micro;


#{$brandcall} {

    .MuiTab-root {

        color: $color-text-muitabs;
        text-transform: capitalize;
        padding: 0px;
        min-width: auto;
        font-size: $font-size-muitabs-base;
        margin-right: 20px;

        &:hover {
            // background-color: $color-list-muitabs-hover;
        }
    }

    .MuiTab-root.Mui-selected {
        color: $color-text-muitab-active;
        font-weight: $font-weight-muitabs-bolder;

    }

    .MuiTabs-indicator {
        // border-bottom: 1px solid $color-border-muitabs-active;
        color: $color-text-muitabs;
        font-weight: $font-weight-muitabs-bolder;
    }
}

// Do not edit directly
// Generated on Tue, 05 Mar 2024 12:15:54 GMT

$foundations-font-weight-400: 400;
$foundations-font-weight-450: 450;
$foundations-font-weight-500: 500;
$foundations-font-weight-550: 550;
$foundations-font-weight-600: 600;
$foundations-font-weight-650: 650;
$foundations-font-weight-700: 700;
$foundations-font-weight-750: 750;
$foundations-font-weight-800: 800;

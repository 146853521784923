$font-size-muiform-small: $font-small;
$font-size-muiform-base: $font-base;

$icon-size-muiform-base: $icon-base;
$icon-size-muiform-medium: $icon-medium;
$font-weight-muiform-bold: $text-bolder;
$color-text-muiform: $black-800;
$color-text-muiform-helptext: $black-600;
$color-text-muimandatory: $danger-700;
$color-border-muiinput: $black-400;
$color-text-muiinput: $black-1000;

$color-text-muilink: $primary-600;
$color-text-muilink-hover: $primary-900;
$font-size-muilink-base: $font-base;
$font-weight-muilink: 550;

$color-text-muiform-danger: $danger-700;
$color-text-muiform-success: $success-700;
$line-height-muiform-small: $lineheight-small;
$color-background-muiinput: $white;
$color-background-muiinput-black-hover: $black-900;
$border-color-muiborder-hover: $primary-500;
$border-color-muiborder-focus: $primary-500;
$color-asterisk-muiform: $danger-800;
$color-radio-text: $black-700;
$color-checkbox-text: $black-base;
$size-checkbox: $size-small;
$space-input-muiform: $space-condensed $space-compact;
$border-radius-input-none: $border-radius-none;
$border-radius-input-base: $border-radius-base;
$space-border-none-input: $space-nospace;
$space-autocomplete-custom: $space-nano;
$space-autocomplete-input: $space-condensed;
$size-font-muiinput: $font-base;
$color-background-muichip: $black-100;
$size-font-muichip-label: $font-small;
$color-text-muichip: $black-1000;
$weight-text-chip: $text-bold;
$size-font-chip: $icon-small;
$color-input-disabled: $black-100;
//validations

$color-border-success-muivalidation: $success-800;
$color-text-success-muivalidation: $success-800;
$color-border-danger-muivalidation: $danger-700;
$color-border-warning-muivalidation: $warning-700;
$color-text-warning-muivalidation: $warning-700;
$border-none-muivalidation: none;
$size-font-radio: $font-base;
$weight-text-radio: $text-bold;
$color-text-radio: $black-800;

$color-text-labelvalue: $black-700;
$size-font-labelvalue: $font-base;
$weight-text-labelvalue: $text-bold;
$line-height-text-labelvalue: $lineheight-base;

//validations

// // Horizontal Forms Start
// .MuiFormControl-root {
//     margin-bottom: 20px;

//     .MuiInputLabel-root {
//         font-size: $font-size-muiform-base;
//         padding: 0 0 $space-micro 0;
//         font-weight: $font-weight-muiform-bold;
//         color: $color-text-muiform;
//         display: block;
//     }

//     span {
//         color: $color-text-muimandatory;
//     }

//     .MuiOutlinedInput-root {
//         display: block;
//         width: 100%;
//         padding: $space-condensed $space-compact ;
//         line-height: $line-height-muiform-small;
//         font-size: $font-size-muiform-base;
//         color: $color-text-muiform;
//         background-color: $color-background-muiinput;
//         background-clip: padding-box;
//         border: 2px solid $color-border-muiinput;
//         appearance: none;
//         transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

//         &:hover {
//             color: $color-background-muiinput-black-hover;
//             background-color: $color-background-muiinput;
//             border: 2px solid $border-color-muiborder-hover;
//             outline: 0;
//             box-shadow: none;
//         }

//         &:focus {
//             color: $color-text-muiinput;
//             background-color: $color-background-muiinput;
//             border: 2px solid $border-color-muiborder-focus;
//             outline: 0;
//             box-shadow: none;
//         }

//     }

//     .MuiFormHelperText-root {
//         font-size: $font-size-muiform-small;
//         color: $color-border-muiinput;
//     }

//     .MuiFormHelperText-root-count {
//         float: right;
//         font-size: $font-size-muiform-small;
//         color: $color-border-muiinput;
//     }
// }

// .Mui-error {
//     .MuiInputLabel-root {
//         border-color: $color-text-muiform-danger !important;

//         &:hover {
//             border-color: $color-text-muiform-danger !important;
//         }
//     }

//     div {
//         color: $color-text-muiform-danger;
//         margin-top: $space-condensed;
//         font-size: $font-size-muiform-base;
//     }

//     span {
//         font-size: $font-size-muiform-small;
//     }

// }

// .Mui-success {
//     .MuiInputLabel-root {
//         border-color: $color-text-muiform-success !important;

//         &:hover {
//             border-color: $color-text-muiform-success !important;
//         }
//     }

//     div {
//         color: $color-text-muiform-success;
//         font-size: $font-size-muiform-base;
//         margin-top: $space-condensed;
//     }

//     span {
//         font-size: $font-size-muiform-small;
//     }
// }

// .MuiFormCount-root {
//     input {
//         padding-left: $space-spacious !important;
//         width: 96% !important;
//     }

//     em {
//         position: relative;
//         top: -56px;
//         z-index: 1;
//         color: $black-400;
//         font-size: $icon-size-muiform-base;

//         &.icon-search2 {
//             left: 12px;
//         }

//         &.icon-close-round {
//             left: 505px !important;
//             color: $black-500;
//             cursor: pointer;
//             font-size: $icon-size-muiform-medium;
//         }
//     }

// }

// input[type="text"]:disabled {
//     background-color: $black-100 !important;

// }

// Mahalakshmi
#{$brandcall} {
  // quick form fixes
  .MuiFormControl-root
    .MuiInputAdornment-root.MuiInputBase-adornedEnd
    fieldset {
    top: 0;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd
    fieldset {
    top: 0;
  }

  .MuiFormControl-root
    .MuiFormControl-root.MuiTextField-root
    .MuiInputBase-root.MuiOutlinedInput-root
    fieldset {
    top: 0;
  }

  .MuiInputBase-root .MuiOutlinedInput-root.MuiOutlinedInput-root fieldset {
    top: 0;
  }

  //End  quick form fixes

  .form-control {
    @extend .mui_input;
    border: 2px solid $color-border-input;
    border-radius: $border-radius-input-base;

    &:hover {
      border: 2px solid $border-color-muiborder-hover;
    }

    &:focus-visible {
      outline: none;
      border: 2px solid $border-color-muiborder-hover;
    }
  }

  .MuiCheckbox-root .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }

  .MuiCheckbox-root {
    span {
      color: $color-checkbox-text;
    }
  }

  .MuiRadio-root .MuiSvgIcon-root {
    width: $size-checkbox;
    height: $size-checkbox;
  }

  .mui_label {
    transform: none !important;
    position: relative;
    font-size: $size-text-label;
    padding: 0 0 $space-micro 0;
    font-weight: $font-weight-muiform-bold;
    color: $color-text-label;
    left: 0px;
    max-width: inherit;
    text-align: left;
    font-family: $font-family-base;
  }

  .mui_input {
    padding: $space-input-muiform;
    line-height: $line-height-muiform-small;
    font-size: $font-size-muiform-base;
    color: $color-text-input;
    font-family: $font-family-base;
  }

  .mui_textarea {
    line-height: $line-height-muiform-small;
    font-size: $font-size-muiform-base;
    color: $color-text-input;
    font-family: $font-family-base;
  }

  /* Mui Textarea*/
  .textarea-custom {
    @extend .mui_input;
    border: 2px solid $color-border-input;
    border-radius: $border-radius-input-base;

    &:hover {
      border: 2px solid $border-color-muiborder-hover;
    }

    &:focus-visible {
      outline: none;
      border: 2px solid $border-color-muiborder-hover;
    }
  }

  //**MuiAutocomplete**//
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiFormLabel-root {
        @extend .mui_label;
      }

      .MuiInputBase-root {
        @extend .mui_input;

        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border: 2px solid $border-color-muiborder-hover;
          }
        }

        &:focus {
          border: 2px solid $border-color-muiborder-focus;
        }

        input {
          padding: $space-border-none-input;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border: 2px solid $color-border-input;
        border-radius: $border-radius-input-base;

        &:hover {
          border: 1px solid $border-color-muiborder-hover;
        }

        span {
          display: none !important;
        }
      }
    }

    transform: none !important;
  }

  //Testing
  .MuiInputBase-root {
    @extend .mui_input;

    .MuiOutlinedInput-root.Mui-disabled {
      .MuiOutlinedInput-notchedOutline {
        border: 2px solid $color-border-input;
      }
    }

    .MuiFormLabel-root {
      .MuiFormLabel-asterisk {
        color: $color-asterisk-muiform;
      }

      p.MuiTypography-root {
        font-size: $font-size-muiform-small;
        color: $color-text-muiform-helptext;
      }
    }

    .MuiFormControlLabel-root {
      // .MuiRadio-root {
      //     color: $color-radio-text;
      //     font-size: 14px;
      // }

      .MuiTypography-root {
        color: $color-radio-text;
      }
    }

    p {
      margin-left: $space-border-none-input;
      font-weight: 100;
    }

    &:hover {
      .MuiOutlinedInput-notchedOutline {
        border: 2px solid $border-color-muiborder-hover;
      }
    }

    &:focus {
      border: 2px solid $border-color-muiborder-focus;
    }

    input {
      padding: $space-border-none-input;
      font-size: $size-font-muiinput;

      &:placeholder-shown {
        text-overflow: ellipsis;
        display: inline-block;
        max-width: 90%;
      }
    }

    .MuiSelect-select {
      padding: $space-border-none-input;
      text-align: left;
      min-height: 16px;
    }
  }

  .MuiFormLabel-root {
    @extend .mui_label;
  }

  // End Testing

  .MuiFormControl-root {
    // width: 45%;

    .MuiFormLabel-root {
      @extend .mui_label;

      .MuiFormLabel-asterisk {
        color: $color-asterisk-muiform;
      }
    }

    .MuiTextField-root textarea.MuiInputBase-input {
      @extend .mui_textarea;
    }

    .MuiInputBase-root {
      @extend .mui_input;

      .MuiFormLabel-root {
        .MuiFormLabel-asterisk {
          color: $color-asterisk-muiform;
        }
      }

      &.Mui-disabled {
        background: $color-input-disabled;
      }

      .MuiFormControlLabel-root {
        // .MuiRadio-root {
        //     color: $color-radio-text;
        //     font-size: 14px;
        // }

        .MuiTypography-root {
          color: $color-radio-text;
        }
      }

      p {
        margin-left: $space-border-none-input;
        font-weight: 100;
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border: 2px solid $border-color-muiborder-hover;
        }
      }

      &:focus {
        border: 2px solid $border-color-muiborder-focus;
      }

      input {
        padding: $space-border-none-input;
        font-size: $size-font-muiinput;
      }

      .MuiSelect-select {
        padding: $space-border-none-input;
        text-align: left;
        min-height: 16px;
      }

      &.Mui-error {
        fieldset.MuiOutlinedInput-notchedOutline {
          border-color: $color-border-danger-muivalidation;
        }
      }
    }

    > .MuiInputBase-root {
      > fieldset {
        top: 0;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: 2px solid $color-border-input;
      border-radius: $border-radius-input-base;
      border-color: $color-border-input;

      &:hover {
        border: 1px solid $border-color-muiborder-hover;
      }

      span {
        display: none;
      }
    }

    //validations
    .Mui-error {
      margin-top: $space-nospace;
      color: $color-asterisk-muiform;

      &.MuiFormHelperText-root {
        color: $color-asterisk-muiform;
      }

      &::before {
        top: 0px;
        border: 2px solid $color-border-danger-muivalidation;
      }

      &::after {
        border: $border-none-muivalidation;
      }

      input {
        color: $color-text-muiform-helptext;
      }
    }

    .MuiFormHelperText-root {
      font-size: $font-size-muiform-small;
      color: $color-text-muiform-helptext;
      letter-spacing: 0;
    }

    .MuiInputBase-colorSuccess {
      margin-top: $space-nospace;

      .MuiOutlinedInput-notchedOutline {
        border: 2px solid $color-border-success-muivalidation;
      }

      + p {
        color: $color-text-success-muivalidation;
      }

      &::before {
        top: 0px;
        border: 2px solid $color-border-success-muivalidation !important;
      }

      &::after {
        border: $border-none-muivalidation;
      }
    }

    .MuiInputBase-colorWarning {
      margin-top: $space-nospace;

      .MuiOutlinedInput-notchedOutline {
        border: 2px solid $color-border-warning-muivalidation;
      }

      + p {
        color: $color-text-warning-muivalidation;
      }

      &::before {
        top: 0px;
        border: 2px solid $color-border-warning-muivalidation !important;
      }

      &::after {
        border: $border-none-muivalidation;
      }
    }

    p {
      margin-left: 0px;
    }
  }

  // .form-group {
  //     .MuiFormControl-root {
  //         &.MuiTextField-root.custom-date-picker {
  //             .MuiInputBase-root.MuiOutlinedInput-root {
  //                 padding-right: 8px;

  //                 >input {
  //                     @extend .mui_input;
  //                 }
  //             }
  //         }
  //     }
  // }

  .MuiPaper-root {
    ul {
      li {
        font-size: $font-size-muiform-base !important;
      }
    }
  }

  .MuiBox-root {
    .MuiTextField-root {
      // width: 45%;
    }
  }

  .MuiFormGroup-root {
    span {
      font-size: $size-font-radio;
      font-family: $font-family-base;
      font-weight: $weight-text-radio;
    }
  }

  //Autocomplete

  .autocomplete-custom {
    .MuiFormControl-root {
      .MuiInputBase-root {
        padding-top: $space-autocomplete-input;
        padding-bottom: $space-autocomplete-input;

        .MuiChip-root {
          height: 25px;
          background-color: $color-background-muichip;
          font-family: $font-family-base;
          font-weight: $weight-text-chip;
          // margin: $space-nospace;

          .MuiChip-label {
            font-size: $size-font-chip;
            font-weight: $weight-text-chip;
          }
        }
      }
    }
  }

  //validations
  .label_text {
    @extend .mui_label;
    display: block;
  }

  .Mui-disabled-label-form {
    .Mui-disabled-label-form
      .MuiFormControl-root
      .MuiInputBase-root.Mui-disabled:focus {
      border: 2px solid transparent;
      cursor: default;
    }

    .Mui-disabled {
      cursor: default;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 2px solid $black-200 !important;
    }
  }

  .MuiSearch {
    .MuiOutlinedInput-notchedOutline {
      top: 0px;
    }

    em {
      font-size: $font-base;
    }
  }

  a.MuiLink-root.MuiTypography-root {
    color: $color-text-muilink;
    text-decoration: none;
    font-size: $font-size-muilink-base;
    font-weight: $font-weight-muilink;
    letter-spacing: 0;

    &:hover {
      color: $color-text-muilink-hover;
    }
  }
}

// .MuiInputAdornment-root {
//     .MuiIconButton-root {
//         em {
//             font-size: $font-large;
//         }
//     }
// }

//quick fix for products
#{$brandcall} {
  .form-group {
    .MuiFormControl-root
      label.MuiFormLabel-root
      svg.MuiSvgIcon-root[data-testid="InfoOutlinedIcon"] {
      color: #666;
    }

    .MuiFormControl-root .MuiOutlinedInput-root {
      padding: 0.5rem 0.75rem;
    }

    > .MuiFormControl-root {
      margin-bottom: 24px;
      row-gap: 0;
    }

    .MuiFormControl-root .MuiChip-filled.MuiChip-filledDefault {
      margin: 0 4px !important;
    }

    // .MuiAutocomplete-root {
    //     .MuiFormControl-root {
    //         .MuiInputBase-root.MuiOutlinedInput-root {
    //             padding: 0.442rem 0.55rem;
    //         }
    //     }
    // }
  }
}
//Temporary fix
#date-picker-popover.MuiPopover-root
  .MuiFormControl-root.custom-date-picker
  .MuiInputBase-root {
  padding: 6px 8px;
  &::before,
  &::after {
    opacity: 0;
  }
}
#date-picker-popover.MuiPopover-root .MuiFormControl-root {
  margin: 0;
  min-width: 160px;
}
#date-picker-popover.MuiPopover-root .MuiFormControl-root.custom-date-picker {
  padding: 0;
  border: 2px solid #d9d9d9;
  border-radius: 3px;
  margin: 0;
  margin-bottom: 8px;
}
[class*="layouts_layout__"] {
  .layout-right.app-content > .page-wrapper > .customer-page {
    overflow-y: hidden;
  }
}
//start regulations
#{$brandcall} {
  .custom_error {
    .MuiInputBase-root {
      .MuiOutlinedInput-notchedOutline {
        border-color: $danger-base;
      }
    }
    &.MuiTextarea-root {
      border-color: $danger-base;
    }
    .tox-tinymce {
      border-color: $danger-base;
    }
  }
}
//end regulations

//start form view pages
#{$brandcall} {
  .field.field-string
    .MuiFormControl-root
    .MuiBox-root
    .MuiFormLabel-root
    + .MuiBox-root {
    color: $color-text-labelvalue;
    font-size: $size-font-labelvalue;
    font-weight: $weight-text-labelvalue;
    line-height: $line-height-text-labelvalue;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: $space-nospace;
  }
  .MuiFormControl-root {
    label.MuiFormLabel-root {
      .MuiBox-root:nth-child(2) {
        font-size: var(--ff-font-size-body-verysmall);
        color: var(--ff-black-700);
        right: 0;
        margin: 0;
      }
    }
  }
}
//end form view pages

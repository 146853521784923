@import "../../tokens/scss/alltokens.scss";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
// @import url('https://api.fontshare.com/v2/css?f[]=work-sans@200,300,500,600,700,400&display=swap');
// @import url('https://api.fontshare.com/v2/css?f[]=supreme@800,400,401,100,300,301,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Raleway:ital,wght@0,100..900;1,100..900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

//$font-family-base : $foundations-font-familybase;
$font-family-base: "Nunito Sans", "Open Sans", "IBM Plex Sans", "Raleway", "Urbanist", "Montserrat", sans-serif;

body {
    font-family: $font-family-base;
}

$brandcall : ".freya-fusion";

//$font-family: 'Open Sans', sans-serif;
$font-family: $font-family-base;

* {
    font-family: $font-family !important;
}

/****** header's start ******/
$heading-level1: $foundations-font-size-heading-level1; 
$heading-level2: $foundations-font-size-heading-level2;
$heading-level3: $foundations-font-size-heading-level3;
$heading-level4: $foundations-font-size-heading-level4;
$heading-level5: $foundations-font-size-heading-level5;
/****** header's start ******/

/****** font sizes start ******/

$font-small : $foundations-font-size-body-verysmall;/****** 12px ******/
$font-base : $foundations-font-size-body-base;/****** 14px ******/
$font-medium : $foundations-font-size-body-medium;/****** 16px ******/
$font-large : $foundations-font-size-body-large;/****** 18px ******/

$icon-small: $foundations-icon-size-small;
$icon-base: $foundations-icon-size-base;
$icon-medium: $foundations-icon-size-medium;
$icon-large: $foundations-icon-size-large;
$icon-lead: $foundations-icon-size-lead;




/****** font sizes end ******/

/****** font bold start ******/
$text-regular : $foundations-font-weight-400;
$text-bold : $foundations-font-weight-500;
$text-bolder : $foundations-font-weight-600;
$text-bolder-650 :$foundations-font-weight-650;


/****font line-height start ****/
$lineheight-small: $foundations-font-lineheight-small;
$lineheight-base: $foundations-font-lineheight-base;
$lineheight-medium: $foundations-font-lineheight-medium;
$lineheight-large: $foundations-font-lineheight-large;


/******font letter spacing start ******/
// $letterspacing-1: $foundations-letterspacing-1;
// $letterspacing-tight: $foundations-letterspacing-tight;
// $letterspacing-base: $foundations-letterspacing-base;
// $letterspacing-loose: $foundations-letterspacing-loose;


/***primary colors start ****/
$primary-base: $foundations-primary-base;
$primary-100: $foundations-primary-100;
$primary-200: $foundations-primary-200;
$primary-300: $foundations-primary-300;
$primary-400: $foundations-primary-400;
$primary-500: $foundations-primary-500;
$primary-600: $foundations-primary-600;
$primary-700: $foundations-primary-700;
$primary-800: $foundations-primary-800;
$primary-900: $foundations-primary-900;

$success-base: $foundations-success-base;
$success-100: $foundations-success-100;
$success-200: $foundations-success-200;
$success-300: $foundations-success-300;
$success-400: $foundations-success-400;
$success-500: $foundations-success-500;
$success-600: $foundations-success-600;
$success-700: $foundations-success-700;
$success-800: $foundations-success-800;
$success-900: $foundations-success-900;

$danger-base: $foundations-danger-700;
$danger-100: $foundations-danger-100;
$danger-200: $foundations-danger-200;
$danger-300: $foundations-danger-300;
$danger-400: $foundations-danger-400;
$danger-500: $foundations-danger-500;
$danger-600: $foundations-danger-600;
$danger-700: $foundations-danger-700;
$danger-800: $foundations-danger-800;
$danger-900: $foundations-danger-900;

$warning-base: $foundations-orange-700;
$warning-100: $foundations-orange-100;
$warning-200: $foundations-orange-200;
$warning-300: $foundations-orange-300;
$warning-400: $foundations-orange-400;
$warning-500: $foundations-orange-500;
$warning-600: $foundations-orange-600;
$warning-700: $foundations-orange-700;
$warning-800: $foundations-orange-800;
$warning-900: $foundations-orange-900;

/******black colors start ******/
$black-50: $foundations-black-50;
$black-base: $foundations-black-200;
$black-100: $foundations-black-100;
$black-200: $foundations-black-200;
$black-300: $foundations-black-300;
$black-400: $foundations-black-400;
$black-500: $foundations-black-500;
$black-600: $foundations-black-600;
$black-700: $foundations-black-700;
$black-800: $foundations-black-800;
$black-900: $foundations-black-900;
$black-1000: $foundations-black-1000;

/******purple colors start ******/
$purple-100:$foundations-purple-100;
$purple-200:$foundations-purple-200;
$purple-300:$foundations-purple-300;
$purple-400:$foundations-purple-400;
$purple-500:$foundations-purple-500;
$purple-600:$foundations-purple-600;
$purple-700:$foundations-purple-700;
$purple-800:$foundations-purple-800;
$purple-900:$foundations-purple-900;


$black: $foundations-neutral-black;
$white: $foundations-neutral-white;

/*****border*******/
$no-border: $foundations-border-width-no-border;
$border-thin: $foundations-border-width-thin;
$border-small: $foundations-border-width-small;
$border-medium: $foundations-border-width-medium;

$border-radius-none: $foundations-border-width-no-border;
$border-radius-base: $foundations-border-radius-base;
$border-radius-secondary: $foundations-border-radius-secondary;
$border-radius-rounded: $foundations-border-radius-rounded;

/***space***/
$space-nospace: $foundations-space-nospace; /***0px***/
$space-nano: $foundations-space-nano; /***2px ***/
$space-micro: $foundations-space-micro;/***4px***/ 
$space-condensed: $foundations-space-condensed;/***8px***/ 
$space-compact: $foundations-space-compact;/***12px***/ 
$space-default: $foundations-space-default; /***16px***/
$space-comfortable: $foundations-space-comfortable;/***24px***/ 
$space-spacious : $foundations-space-spacious;/***32px***/ 
$space-generous: $foundations-space-generous;/***40px***/ 
$space-vast: $foundations-space-vast;/***48px***/ 

/***size***/
$size-small: $foundations-size-small;
$size-medium: $foundations-size-medium;
$size-large: $foundations-size-large;
$space-padding-box-a: $space-default;
$font-size-box-base: $font-base;
$color-text-box: $black-900;

#{$brandcall} {
.box-component.MuiBox-root {
   padding: $space-padding-box-a !important;
   font-size: $font-size-box-base;
   color: $color-text-box;
   border: 1px dashed $black-600 !important;
   width: auto !important;
}
}


$color-background-dialog-button: $primary-600;
$color-background-dialog-button-hover: $primary-700;
$color-text-dialog-button: $white;
$border-none-dialog-button: $no-border;
$border-color-dialog: $black-100;
$space-nospace-muidialog: $space-nospace;
$space-microspace-muidialog: $space-micro;
$font-base-muidialog-content: $font-base;
$font-small-muidialog: $font-small;
$color-text-muidialog-content: $black-1000;
$space-icon-action: $space-condensed $space-default;
$font-size-muidialog-header-title: $font-large;
$space-content-muidialog: $space-default;

$weight-font-muidialog-bolder: $text-bolder;

$color-background-muidialogbackdrop: #f6f8f9;

#{$brandcall} {
  .MuiDialog-root {
    .MuiBackdrop-root.MuiModal-backdrop {
      background-color: $color-background-muidialogbackdrop;
    }

    .MuiDialog-container {
      // align-items: flex-start;

      .MuiDialog-paper {
        .MuiDialogTitle-root {
          margin: $space-nospace-muidialog;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid $border-color-dialog;
          //padding: $space-icon-action;
          padding: 0.6rem 1.6rem;

          h5 {
            margin: $space-microspace-muidialog $space-nospace-muidialog;
            font-weight: $weight-font-muidialog-bolder;
            font-size: $font-size-muidialog-header-title;
          }

          .close {
            background-color: transparent;
            border: $border-none-dialog-button;
            cursor: pointer;
            padding: $space-nospace-muidialog;
          }
        }

        .MuiDialogContent-root {
          padding: 1.2rem;
          //  padding: $space-content-muidialog;
          border-bottom: 1px solid $border-color-dialog;

          p {
            font-size: $font-base-muidialog-content;
            color: $color-text-muidialog-content;
            &.MuiFormHelperText-root.Mui-error {
              color: $color-asterisk-muiform;
              font-size: $font-small-muidialog;
            }
          }
        }

        .MuiDialogActions-root {
          // padding: $space-icon-action;
          padding: $space-default $space-comfortable;
          // .MuiButtonBase-root {
          //     background-color: $color-background-dialog-button;
          //     color: $color-text-dialog-button;
          //     border: $border-none-dialog-button;
          //     cursor: pointer;

          //     &:hover {
          //         background-color: $color-background-dialog-button-hover;
          //     }

          // }
        }
      }
    }
  }

  > .MuiDialog-root.dialog-customization .MuiBackdrop-root.MuiModal-backdrop {
    background: rgba(0, 0, 0, 0.5);
  }
}

// Do not edit directly
// Generated on Tue, 05 Mar 2024 12:15:54 GMT

$foundations-space-nospace: 0;
$foundations-space-nano: 2px;
$foundations-space-micro: 4px;
$foundations-space-condensed: 8px;
$foundations-space-compact: 12px;
$foundations-space-default: 16px;
$foundations-space-littlecomfort: 20px;
$foundations-space-comfortable: 24px;
$foundations-space-morecomfortable: 28px;
$foundations-space-spacious: 32px;
$foundations-space-generous: 40px;
$foundations-space-vast: 48px;
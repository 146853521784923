$color-text-typography-form-view: $black-1000;
$color-text-typography: $black-900;
$color-text-typography-formview: $black-700;

$heading-level2-h1-typography: $heading-level2;
$heading-level2-h2-typography: $heading-level5;
$heading-level2-h3-typography: $heading-level3;

$display-level1-h2-typography: 28px;
$display-level2-h4-typography: $heading-level1;

$text-regular-typography: $text-regular;
$text-bold-typography: $text-bold;
$text-bolder-typography: $text-bolder;
$text-boldest-typography: $text-bolder-650;

$line-height-large-typography: $lineheight-large;
$line-height-extra-large-typography: 40px;
$line-height-typography-32: 32px;
$line-height-typography-28: 28px;
$line-height-base-typography: $lineheight-base;
$line-height-typography-20: 20px;
$line-height-small-typography: $lineheight-small;

$font-size-typography-small: $font-small;
$font-size-typography-base: $font-base;
$font-size-typography-medium: $font-base;

#{$brandcall} {
  // max-width:fit-content;

  // .MuiTypography-root {
  //     //  margin: $space-nospace;
  //     padding: $space-nospace;
  // }

  // //Heading Level//
  // .MuiTypography-h1 {
  //     color: $color-text-typography;
  //     font-size: $heading-level2-h1-typography;
  //     font-weight: $text-boldest-typography;
  //     line-height: $line-height-extra-large-typography;
  // }

  // .MuiTypography-h2 {
  //     color: $color-text-typography;
  //     font-size: $heading-level2-h2-typography;
  //     font-weight: $text-boldest-typography;
  //     line-height: $line-height-large-typography;
  // }

  // .MuiTypography-h3 {
  //     color: $color-text-typography;
  //     font-size: $heading-level2-h3-typography;
  //     font-weight: $text-bolder-typography;
  //     line-height: $line-height-typography-32;
  //     margin-top: 0px;
  // }

  // //End Heading Level//

  // //Display Level//
  // .MuiTypography-h4 {
  //     font-size: $display-level1-h2-typography;
  //     font-weight: $text-bolder-typography;
  //     color: $color-text-typography;
  // }

  // .MuiTypography-h5 {
  //     font-size: $display-level2-h4-typography;
  //     font-weight: $text-bolder-typography;
  //     color: $color-text-typography;

  // }

  // //End Display Level//

  // //Font weights//
  // .MuiTypography-body1 {
  //     font-size: $font-base;
  //     color: $color-text-typography;
  //     font-weight: $text-regular ;
  // }

  // .MuiTypography-subtitle2 {
  //     font-size: $font-base;
  //     color: $color-text-typography;
  //     font-weight: $text-bold ;
  // }

  // .MuiTypography-h6 {
  //     font-size: $font-medium;
  //     color: $color-text-typography;
  //     font-weight: $text-bolder ;
  // }

  .heading-typography {
    font-size: $font-large;
    color: $color-text-typography;
    font-weight: $text-bolder-650;
  }

  //End Font weights variant//

  //font weights //
  .MuiTypography-body1 {
    // font-size: $font-base;
    // color: $color-text-typography;
    // margin-bottom: $space-default;
    &.regular {
      font-weight: $text-regular;
    }
    &.bold {
      font-weight: $text-bold;
    }
    &.bolder {
      font-weight: $text-bolder;
    }
    &.boldest {
      font-weight: $text-bolder-650;
    }
  }

  //font weights//

  //Body text//
  // .MuiTypography-body2 {
  //     font-size: $font-base;
  //     color: $color-text-typography;
  //     font-weight: $text-regular ;
  // }

  // End Body text//

  //span text//
  // .MuiTypography-body1>span {
  //     font-size: $font-base;
  //     color: $color-text-typography;
  //     font-weight: $text-regular ;
  // }

  // End span text//
}
#{$brandcall} {
  .form-group {
    .form-label {
      font-size: $font-size-typography-small;
      color: $color-text-typography-form-view;
      font-weight: $text-bolder-typography;
    }

    .form-content {
      color: $color-text-typography-formview;
      font-size: $font-size-typography-medium;
      font-weight: $text-bold-typography;
      line-height: $line-height-base-typography;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
  }

  // Form view //

  //table view//
  .typography-table {
    border-collapse: collapse;
    width: 100%;
    font-size: $font-base;

    thead,
    tbody,
    tfoot {
      border-bottom: 2px solid $black-200;
    }

    td,
    th {
      border: none;
      padding: $space-condensed $space-condensed;
      text-align: left;
    }

    .pt-24 {
      padding-top: 24px;
    }
  }
}

// Container
$display-flex: flex;
$flex-start: flex-start;
$flex-center: flex-center;
$flex-end: flex-end;
$flex-stretch: flex-stretch;
$flex-nowrap: flex-wrap;

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;

}

.row {
    display: $display-flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    // margin-left: - $grid-gap;
    // margin-right: - $grid-gap;
}

.col {
    display: $display-flex;
    flex-wrap: wrap;

}

.col-1 {

    flex-grow: 1;
}

.col-2 {

    flex-grow: 2;
}

.col-3 {

    flex-grow: 4;
}

.col-4 {

    flex-grow: 3;
}

.col-5 {

    flex-grow: 4.5;
}

.col-6 {

    flex-grow: 2;
}

.col-7 {

    flex-grow: 2.5;
}

.col-8 {

    flex-grow: 3;
}

.col-9 {

    flex-grow: 4;
}

.col-10 {

    flex-grow: 2;
}

.col-11 {

    flex-grow: 1;
}

.col-12 {

    flex-grow: 12;
}

// // Creating Cols Media Query
// @each $modifier , $colbreakpoint in $grid-columnsset {
//   @include create-col-classes($modifier, $grid__cols, $colbreakpoint);

// }



.box {
    background-color: $black-100;
    padding: $space-compact;
    justify-content: left;
    border: $border-thin solid $black-200;

    .item {
        display: $display-flex;
    }

}

.flex-start {
    justify-content: $flex-start;
}

.flex-center {
    justify-content: $flex-center;
}

.flex-end {
    justify-content: $flex-end;
}

.flex-stretch {
    height: 100px;
    display: $display-flex;
    align-items: $flex-stretch;
}

.flex-nowrap {
    display: $display-flex;
    flex-wrap: $flex-nowrap;
}

.flex-shrink {
    flex-shrink: 3;
}

.wrap-box {
    width: 300px
}

@media only screen and (max-width: 992px) {
    .col-1 {
        flex: 0 0 8.33%;
    }

    .col-2 {
        flex: 0 0 16.66%;
    }

    .col-3 {
        flex: 0 0 25%;
    }

    .col-4 {
        flex: 0 0 33.33%;
    }

    .col-5 {
        flex: 0 0 41.66%;
    }

    .col-6 {
        flex: 0 0 50%;
    }

    .col-7 {
        flex: 0 0 58.33%;
    }

    .col-8 {
        flex: 0 0 66.66%;
    }

    .col-9 {
        flex: 0 0 75%;
    }

    .col-10 {
        flex: 0 0 83.33%;
    }

    .col-11 {
        flex: 0 0 91.66%;
    }

    .col-12 {
        flex: 0 0 100%;
    }

}
@media only screen and (max-width: 600px) {
    .col-1 {
        flex: 0 0 100%;
    }

    .col-2 {
        flex: 0 0 100%;
    }

    .col-3 {
        flex: 0 0 100%;
    }

    .col-4 {
        flex: 0 0 100%;
    }

    .col-5 {
        flex: 0 0 100%;
    }

    .col-6 {
        flex: 0 0 100%;
    }

    .col-7 {
        flex: 0 0 100%;
    }

    .col-8 {
        flex: 0 0 100%;
    }

    .col-9 {
        flex: 0 0 100%;
    }

    .col-10 {
        flex: 0 0 100%;
    }

    .col-11 {
        flex: 0 0 100%;
    }

    .col-12 {
        flex: 0 0 100%;
    }

}

// 768px

// @media only screen and (max-width: 768px) {
//     .col-1 {
//         flex: 1 0 0%;
//     }

//     .col-2 {
//         flex: 1 0 0%;
//     }

//     .col-3 {
//         flex: 1 0 0%;
//     }

//     .col-4 {
//         flex: 1 0 0%;
//     }

//     .col-5 {
//         flex: 1 0 0%;
//     }

//     .col-6 {
//         flex: 1 0 0%;
//     }

//     .col-7 {
//         flex: 1 0 0%;
//     }

//     .col-8 {
//         flex: 1 0 0%;
//     }

//     .col-9 {
//         flex: 1 0 0%;
//     }

//     .col-10 {
//         flex: 1 0 0%;
//     }

//     .col-11 {
//         flex: 1 0 0%;
//     }

//     .col-12 {
//         flex: 1 0 0%;
//     }
// }


$font-size-link-base: $font-base;
$color-text-link-primary: $primary-base;


#{$brandcall} {
    .MuiBox-root .MuiLink-root {
        font-size: $font-size-link-base;
        color: $color-text-link-primary;

    }
}

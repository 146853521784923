@import '../abstract/variables';
@import '../abstract/component-variables';

$color-text-muierror: $danger-800;
$color-text-muiaccordion: $black-900;

$font-size-muierror: $font-small;
$font-size-muiaccordion-base: $font-base;
$font-size-muiaccordion-medium: $font-medium;

#{$brandcall} {
    .MuiTypography-root {

        // margin-bottom: 0px;
        .Mui-error {
            color: $color-text-muierror;
            font-size: $font-size-muierror;
        }
    }

    .MuiAccordionSummary-content {
        p {
            font-size: $font-size-muiaccordion-medium;
            color: $color-text-muiaccordion;
            margin-top: $space-nospace;
            margin-bottom: $space-nospace;
        }
    }

    .MuiAccordionDetails-root {
        p {
            font-size: $font-size-muiaccordion-base;
            color: $color-text-muiaccordion;

        }

        .Mui-error {
            color: $color-text-muierror;
            font-size: $font-size-muierror;
        }
    }

    .MuiCollapse-root {
        color: $color-text-muiaccordion;
        font-size: $font-size-muiaccordion-base;
    }

    // .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    //     min-height: 48px;
    // }
    // .MuiAccordionSummary-content.Mui-expanded{
    //     margin: 10px 0px;
    // }
}
$color-text-sidenavigation: $black-900;
$color-text-sidenavigation-primary: $primary-600;
$font-size-sidenavigation-base: $font-base;
$font-size-sidenavigation-large: $font-large;
$font-weight-sidenavigation-regular: $text-regular;
$font-weight-sidenavigation-bold: $text-bold;
$font-weight-sidenavigation-bolder: $text-bolder;
$space-padding-sidenavigation-content-x: $space-comfortable;
$space-padding-sidenavigation-content-y: $space-compact;
$border-color-sidenavigation: $black-300;
$color-background-sidenavigation: $white;
$space-padding-sidenavigation-spacious: $space-spacious;
$space-margin-sidenavigation-search: $space-condensed;

$fontsize-14: 14px;
$fontsize-16: 16px;

$line-height-14: 14px;
$width-sidenav: 280px;

#{$brandcall} {
    .sidenavigation-component {
        width: $width-sidenav;

        .MuiList-root>ul.MuiTreeView-root>li>.MuiTreeItem-content.Mui-selected .MuiTreeItem-label:before {
            content: "";
            position: absolute;
            width: 2px;
            height: 120%;
            background: #4166ee;
            left: -24px;
            top: -10%;
        }

        .MuiList-root>ul.MuiTreeView-root>li>ul li.MuiTreeItem-root>.MuiTreeItem-content.Mui-selected .MuiTreeItem-label:before {
            content: "";
            position: absolute;
            width: 2px;
            height: 120%;
            background: #4166ee;
            left: -38px;
            top: -10%;
        }

        .MuiTreeView-root {
            display: flex;
            // margin: 8px 0;
            float: left;
            width: 100%;
            flex-flow: column;
            background: $color-background-sidenavigation;
            padding: $space-compact $space-nospace;
        }

        .MuiTreeView-root>li {
            padding: $space-nospace;
            font-size: $fontsize-14;

            .MuiTreeItem-iconContainer {
                margin-right: 4px;
                width: 15px;

                svg>path {
                    d: path("M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z");
                }

            }

            &[aria-expanded="true"] {
                .MuiTreeItem-iconContainer {
                    margin-right: 4px;
                    width: 15px;

                    svg>path {
                        d: path("M19 13H5v-2h14z");
                    }

                }
            }

            >ul {
                margin: calc($space-compact - $space-condensed) $space-compact $space-compact;

                li {
                    &:hover {
                        color: $color-text-sidenavigation-primary;
                    }
                }
            }

            >.MuiTreeItem-content {
                padding: $space-padding-sidenavigation-content-y $space-padding-sidenavigation-content-x;
                flex-direction: row-reverse;
            }

            &:hover {
                color: $color-text-sidenavigation-primary;
            }

        }


        // .MuiTreeItem-content .Mui-selected{
        //     background-color: none;
        //     color:red;
        // }
        .MuiTreeItem-root .Mui-selected {
            background-color: transparent;
            color: $color-text-sidenavigation-primary;
        }



        .MuiTreeView-root>li>.MuiTreeItem-content.Mui-selected {
            background: none;
            font-weight: $text-bolder;
        }

        .MuiTreeItem-content:hover {
            background-color: transparent;
        }

        // ul.MuiCollapse-root.MuiCollapse-vertical {
        //     color: $primary-base;
        // }

        // .MuiTreeView-root > li > .MuiTreeItem-content.Mui-selected > div {
        //     font-weight: 500;
        //     color: $primary-base;
        // }



        .MuiTreeView-root>li .MuiTreeItem-label {
            font-size: $font-size-sidenavigation-base !important;
        }

        .MuiTreeView-root>li>ul li {
            float: none;
        }


    }


    .MuiBox-root .rightsidesearch-list {
        float: none;
        width: auto;
        display: contents;
    }

    .rightsidesearch-list {
        .sidenavigation-heading {
            color: $color-text-sidenavigation;
            font-size: $fontsize-16;
            font-weight: $font-weight-sidenavigation-bolder;
            border-bottom: 1px solid $black-200 ;
            display: flex;
        }

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
            // width: 290px;
        }


        input.sidenavigation-search-text {
            color: $color-text-sidenavigation;
            position: absolute;
            z-index: 5;
            transition: z-index 0.8s, width 0.5s, background 0.3s ease, border 0.3s;
            height: 34px;
            width: 28px;
            margin: $space-margin-sidenavigation-search;
            padding: $space-compact;
            box-sizing: border-box;
            font-size: $font-size-sidenavigation-base;
            cursor: pointer;
            border-radius: 16px;
            border: 1px solid transparent;
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUxMiA1MTIiIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0iNTEycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik01MDMuODY2LDQ3Ny45NzRMMzYwLjk1OCwzMzUuMDUyYzI4LjcyNS0zNC41NDQsNDYuMDE3LTc4LjkxMiw0Ni4wMTctMTI3LjMzNiAgYzAtMTEwLjA4NC04OS4yMjctMTk5LjMxMi0xOTkuMzEyLTE5OS4zMTJDOTcuNTk5LDguNDAzLDguMzUxLDk3LjYzMSw4LjM1MSwyMDcuNzE1YzAsMTEwLjA2NCw4OS4yNDgsMTk5LjMxMiwxOTkuMzEyLDE5OS4zMTIgIGM0OC40MzUsMCw5Mi43OTItMTcuMjkyLDEyNy4zMzYtNDYuMDE3bDE0Mi45MDgsMTQyLjkyMkw1MDMuODY2LDQ3Ny45NzR6IE0yOS4zMzEsMjA3LjcxNWMwLTk4LjMzNCw3OS45ODctMTc4LjMzMiwxNzguMzMyLTE3OC4zMzIgIGM5OC4zMjUsMCwxNzguMzMyLDc5Ljk5OCwxNzguMzMyLDE3OC4zMzJzLTgwLjAwNywxNzguMzMyLTE3OC4zMzIsMTc4LjMzMkMxMDkuMzE4LDM4Ni4wNDcsMjkuMzMxLDMwNi4wNSwyOS4zMzEsMjA3LjcxNXoiIGZpbGw9IiMzNzQwNEQiLz48L3N2Zz4=) no-repeat right 8px center transparent;
            background-size: 15px;
            float: right;
            right: 0;

        }

        input.sidenavigation-search-text:focus {
            z-index: 3;
            width: 96%;
            // border: 1px solid $border-color-sidenavigation;
            background-color: $color-background-sidenavigation;
            outline: none;
            cursor: auto;
            position: absolute;
            padding-left: 36px;
            left: auto;
            margin: $space-margin-sidenavigation-search;
            right: 0;
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDUxMiA1MTIiIGhlaWdodD0iNTEycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0iNTEycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik01MDMuODY2LDQ3Ny45NzRMMzYwLjk1OCwzMzUuMDUyYzI4LjcyNS0zNC41NDQsNDYuMDE3LTc4LjkxMiw0Ni4wMTctMTI3LjMzNiAgYzAtMTEwLjA4NC04OS4yMjctMTk5LjMxMi0xOTkuMzEyLTE5OS4zMTJDOTcuNTk5LDguNDAzLDguMzUxLDk3LjYzMSw4LjM1MSwyMDcuNzE1YzAsMTEwLjA2NCw4OS4yNDgsMTk5LjMxMiwxOTkuMzEyLDE5OS4zMTIgIGM0OC40MzUsMCw5Mi43OTItMTcuMjkyLDEyNy4zMzYtNDYuMDE3bDE0Mi45MDgsMTQyLjkyMkw1MDMuODY2LDQ3Ny45NzR6IE0yOS4zMzEsMjA3LjcxNWMwLTk4LjMzNCw3OS45ODctMTc4LjMzMiwxNzguMzMyLTE3OC4zMzIgIGM5OC4zMjUsMCwxNzguMzMyLDc5Ljk5OCwxNzguMzMyLDE3OC4zMzJzLTgwLjAwNywxNzguMzMyLTE3OC4zMzIsMTc4LjMzMkMxMDkuMzE4LDM4Ni4wNDcsMjkuMzMxLDMwNi4wNSwyOS4zMzEsMjA3LjcxNXoiIGZpbGw9IiMzNzQwNEQiLz48L3N2Zz4=) no-repeat left 12px center transparent;
            background-size: 15px;
            background-color: $color-background-sidenavigation;
        }

        .MuiListItemText-root {
            margin-top: 0px;
            margin-bottom: 0px;
            margin-left: 4px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        // .MuiListItemButton-root{
        //     padding: 4px 16px;
        // }

        .MuiListItemButton-root:hover {
            background: $black-100;
            cursor: pointer;
            // color: $primary-base;

        }

        .MuiListItemButton-root:focus {
            color: $color-text-sidenavigation-primary;
            font-weight: $font-weight-sidenavigation-bold;
            background-color: $primary-100;
        }

        input[type="search"]::-webkit-search-cancel-button {
            opacity: 0;
        }

        input[type="search"]:focus::-webkit-search-cancel-button {
            opacity: 1;
        }

        input[type="search"]::-webkit-search-cancel-button {
            -webkit-appearance: none;
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-left: 10px;
            background: linear-gradient(45deg, rgba(114, 114, 114, 0) 0%, rgba(114, 114, 114, 0) 43%, #a6a6a6 45%, #a6a6a6 55%, rgba(114, 114, 114, 0) 57%, rgba(114, 114, 114, 0) 100%), linear-gradient(135deg, transparent 0%, transparent 43%, #a6a6a6 45%, #a6a6a6 55%, transparent 57%, transparent 100%);
        }

        input.sidenavigation-search-text::-webkit-search-cancel-button {
            cursor: pointer;
        }

        .MuiListItemText-root {
            span {
                font-size: $font-size-sidenavigation-base;
                font-weight: $font-weight-sidenavigation-regular;
                line-height: $line-height-14;
            }
        }

        // .sidenavigation-active span {
        //     color: $color-text-sidenavigation-active;
        //     font-weight: $font-weight-sidenavigation-bold;

        // }



    }
}
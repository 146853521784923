$font-size-muibreadcrumb-base: $font-base;
$font-size-muibreadcrumb-large: $font-large;
$font-weight-muibreadcrumb-normal: $text-regular;
$font-weight-muibreadcrumb-bold: $text-regular;
$font-weight-muibreadcrumb-bolder: $text-regular;


$font-size-muibreadcrumb-base: $font-base;
$color-text-muibreadcrumb: $black-600;
$color-text-muibreadcrumb-link: $primary-600;
$color-text-muibreadcrumb-active: $black-700;


$icon-size-muibreadcrumb-medium: $icon-base;


//$color-text-muibreadcrumb: $black-700;
$space-padding-muibreadcrumb-x: $space-condensed;
$space-padding-muibreadcrumb-y: $space-micro;

$space-padding-muibreadcrumb-nospace: $space-nospace;

#{$brandcall} .custom-bread-crumb,
#{$brandcall} .custom-bread-crumb-list {
    // .MuiBreadcrumbs-li {
    //     font-size: $font-size-muibreadcrumb-medium;
    //     color: $color-text-muibreadcrumb;

    //     &>a {
    //         color: $color-text-muibreadcrumb;
    //         text-decoration: none;
    //     }
    // }

    // .MuiBreadcrumbs-li p {
    //     font-weight: $font-weight-muibreadcrumb-bolder;
    //     font-size: $font-size-muibreadcrumb-medium;
    //     margin-bottom: 0px;
    //     margin-top: 0px;
    // }

    // .MuiBreadcrumbs-separator {
    //     .MuiSvgIcon-fontSizeMedium {
    //         font-size: $font-size-muibreadcrumb-large;
    //         padding-top: $space-padding-muibreadcrumb-y;
    //     }
    // }

    ul,
    ol {
        list-style: none;
        padding-left: $space-padding-muibreadcrumb-nospace;

        li {
            display: inline;
            font-size: $font-size-muibreadcrumb-base;
            color: $color-text-muibreadcrumb;

            * {
                font-weight: $font-weight-muibreadcrumb-normal;
            }

            &:last-child {
                font-weight: $font-weight-muibreadcrumb-normal;

                * {
                    font-weight: $font-weight-muibreadcrumb-bolder;
                }
            }

            &+li {
                &:before {
                    padding: $space-padding-muibreadcrumb-x;
                    color: $color-text-muibreadcrumb;
                    content: ">";
                    font-size: $icon-size-muibreadcrumb-medium;
                }
            }

            &>a.MuiTypography-root,
            &>a {
                color: $color-text-muibreadcrumb-link;
                text-decoration: none;
                font-weight: 400;
            }

            &:last-child {
                &>a.MuiTypography-root {
                    color: $color-text-muibreadcrumb-link;
                    text-decoration: none;
                }

                p,
                a,
                >a.MuiTypography-root {
                    font-weight: $font-weight-muibreadcrumb-bolder;
                    font-size: $font-size-muibreadcrumb-base;
                    color: $color-text-muibreadcrumb-active;
                }
            }

            &[aria-hidden="true"] {
                display: none;
            }

            p {
                font-weight: $font-weight-muibreadcrumb-bolder;
                font-size: $font-size-muibreadcrumb-base;
                margin-bottom: 0px;
                margin-top: 2px;
                float: right;
                color: $color-text-muibreadcrumb-active;
            }
        }

        .active {
            font-weight: $font-weight-muibreadcrumb-bolder;
            color: $color-text-muibreadcrumb-active;
        }

    }

    >.MuiStack-root {
        font-weight: $font-weight-muibreadcrumb-bolder;

        &:last-child a {
            font-weight: $font-weight-muibreadcrumb-bolder;
            font-size: $font-size-muibreadcrumb-base;
            color: $color-text-muibreadcrumb-active;
        }

        a.MuiLink-root.MuiTypography-root {
            color: $color-text-muibreadcrumb-link;
            text-decoration: none;
            font-weight: 400;
        }
    }
}

// Quick fixes in application & modules
//Page title
#{$brandcall} {

    .list-header,
    h4.list-header,
    .page-title,
    .list-header-title,
    h4.list-header-title {
        color: #333 !important;
        font-size: 20px !important;
        font-weight: 600 !important;
        letter-spacing: normal !important;
        line-height: 20px !important;
    }

    .header {
        box-shadow: 0 0px 4px 0px rgb(0 0 0 / 16%);
    }
}

// We can remove after Main UI app code moved
#{$brandcall} {
    .layout-left+section.layout-right.app-content {
        .page-wrapper .customer-page {
            padding: 0 !important;
        }

        .filterItemsWrapper>.MuiBox-root {
            margin-left: 26px;
        }
    }
}

// .freya-fusion .layout-right .page-wrapper .customer-page {
//     padding: 0 !important;
// }

// .freya-fusion .layout-right.app-content .page-wrapper .customer-page {
//     padding: 16px 24px 16px 32px !important;
// }
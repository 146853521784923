
// Do not edit directly
// Generated on Tue, 05 Mar 2024 12:15:54 GMT

$foundations-icon-size-small: 12px;
$foundations-icon-size-base: 16px;
$foundations-icon-size-medium: 18px;
$foundations-icon-size-large: 22px;
$foundations-icon-size-lead: 28px;
$foundations-font-size-display-display-1: 2.25rem;
$foundations-font-size-display-display-2: 2rem;
$foundations-font-size-heading-level1: 24px;
$foundations-font-size-heading-level2: 20px;
$foundations-font-size-heading-level3: 16px;
$foundations-font-size-heading-level4: 22px;
$foundations-font-size-heading-level5: 18px;
$foundations-font-size-body-verysmall: 12px;
$foundations-font-size-body-base: 14px;
$foundations-font-size-body-medium: 16px;
$foundations-font-size-body-large: 18px;
